<!--  -->
<template>
  <div class="recordAll" id="recordAll">
    <div class="record" :style="`margin-left:${recordLeft}`">
      <span
        class="btn"
        :id="`id${index}`"
        v-for="(item, index) in tags"
        :key="index"
        @click="changeRecord(item, index)"
        :class="[item.id == active ? 'btn-active' : '']"
        >{{ item.name }}
        <span
          class="record-x"
          @click.stop="close(item, index)"
          >x</span
        >
        </span
      >
    </div>
  </div>
</template>

<script>
import { number } from 'echarts';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  props: {
    tags: Array,
    recordLeft: String,
    active: Number,
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeRecord(item, index) {
      this.$emit("changeRecord", item, index);
    },
    close(item, index) {
      this.$emit("close", item, index);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.record {
  display: inline-flex;
  height: 30px;
  align-items: center;
  box-sizing: border-box;
  padding-top: 2px;
  white-space: nowrap;
  transition: all 0.3s;
}
.recordAll {
  overflow-y: hidden;
  overflow-x: scroll;
  width: 99%;
}
.recordAll::-webkit-scrollbar {
  height: 0;
}
.btn {
  padding: 5px 10px;
  font-size: 12px;
  color: #409eff;
  box-sizing: border-box;
  white-space: nowrap;
  margin-left: 10px;
  cursor: pointer;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  /* border-bottom: 1px solid #eee; */
  transition: width 0.3s;
  width: 100px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  /* color: #fff !important;
  background: #409eff !important; */
}
.btn-active {
  color: #fff !important;
  background: #409eff !important;
}
.record-x {
  display: none;
}
.btn:hover > .record-x {
  display: block !important;
}
.btn:hover {
  width: 120px;
}
</style>