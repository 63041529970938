import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/commodity/listCommodity', data)
}
//修改
export function getEdit(data) {
    return post('psi/commodity/editCommodity', data)
}
//添加
export function getAdd(data) {
    return post('psi/commodity/addCommodity', data)
}
//删除
export function getDel(data) {
    return post('psi/commodity/delCommoditySpec', data)
}
//分类下拉
export function getOption(data) {
    return post('/admin/goods/goodsClassList', data)
}
//供应商分类下拉
export function getOption2(data) {
    return post('psi/vendor/xiala', data)
}
//详情
export function getInfo(data) {
    return post('psi/commodity/infoCommodity', data)
}
//修改状态
export function getState(data) {
    return post('psi/commodity/commoditySta', data)
}
// 修改后的添加商品
export function addCommoditySpec(data) {
    return post('psi/commodity/addCommoditySpec', data)
}
// 修改后的编辑商品接口
export function editCommoditySpec(data) {
    return post('psi/commodity/editCommoditySpec', data)
}
// 获取商品详情 回显的接口
export function getCommoditySpec(data) {
    return post('psi/commodity/getCommoditySpec', data)
}






