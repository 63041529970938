<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />

    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>盘点分析</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入内容" v-model="getInfo.info">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="10">
              <!-- <el-button type="primary" @click="addOpen()">查询</el-button> -->
              <el-button @click="showAddDialog" type="success"
                >批量操作</el-button
              >

              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <el-table
            :row-class-name="getRowClass"
            v-loading="loading"
            class="storage-table"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleCheckedChange"
            :row-key="getRowKeys"
          >
            <el-table-column
              :reserve-selection="true"
              type="selection"
              :selectable="selectable"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="商品名称"
              width="180"
            ></el-table-column>
            <el-table-column prop="spec_name" label="规格"></el-table-column>

            <el-table-column label="库存" width="80px">
              <template slot-scope="scope">
                <div class="warning-red" v-if="scope.row.quantity < 10">
                  {{ scope.row.quantity }}
                </div>
                <div v-else>{{ scope.row.quantity }}</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="unit_price"
              label="采购价(元)"
              width="100"
            ></el-table-column>

            <el-table-column prop="storage_name" label="仓库"></el-table-column>

            <el-table-column label="生产日期/过期时间">
              <template slot-scope="scope">
                <div>{{ scope.row.dom_time }}</div>
                <div>{{ scope.row.deadline }}</div>
              </template>
            </el-table-column>
            <el-table-column label="保质期剩余(天)" width="80px">
              <template slot-scope="scope">
                <div class="warning-red" v-if="scope.row.over <= 7">
                  {{ scope.row.over }}
                </div>
                <div
                  class="warning-orange"
                  v-else-if="scope.row.over > 7 && scope.row.over <= 14"
                >
                  {{ scope.row.over }}
                </div>
                <div
                  class="warning-yellow"
                  v-else-if="scope.row.over > 14 && scope.row.over <= 21"
                >
                  {{ scope.row.over }}
                </div>
                <div v-else class="warning-green">{{ scope.row.over }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="deadline" label="过期时间"></el-table-column> -->
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="editOpen(scope.row)"
                  >编辑</el-button
                ><el-button
                  size="mini"
                  icon="el-icon-edit"
                  @click="openDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChange"
            @size-change="sizeChange"
            :pager-count="15"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 编辑库存 -->
    <el-dialog
      v-dialogDrag
      title="编辑库存"
      :before-close="handleClose1"
      :visible.sync="editStorageDialogVisible"
    >
      <el-form :rules="editRules" :model="editStorageForm">
        <el-form-item label="库存" :label-width="formLabelWidth" prop="num">
          <el-input
            type="number"
            min="1"
            v-model="editStorageForm.num"
            placeholder="库存"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editStorageDialogVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="setAdd" v-show="openState"
              >查 询</el-button
            > -->
        <el-button type="primary" @click="setEdit">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 申请出库总表对话框 -->
    <el-dialog
      v-dialogDrag
      title="出库单"
      style="font-weight:700"
      :before-close="handleClose"
      :visible.sync="addDialogVisible"
      width="80%"
    >
      <el-form
        class="table-form"
        ref="addFormRef"
        :model="addForm"
        :rules="addRules"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="出库类型"
              prop="type"
              :label-width="formLabelWidth"
            >
              <!-- <el-input v-model="addForm.type" placeholder="请输入出库类型" /> -->
              <el-select
                style="width:100%"
                v-model="addForm.type"
                placeholder="请选择出库类型"
              >
                <el-option
                  v-for="item in addType"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="出库时间"
              prop="apply_time"
              :label-width="formLabelWidth"
            >
              <el-input :disabled="true" v-model="addForm.apply_time" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="出库单号"
              prop="outbound_no"
              :label-width="formLabelWidth"
            >
              <el-input :disabled="true" v-model="addForm.outbound_no" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="店铺"
              prop="app_id"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="addForm.app_id"
                style="width: 100%"
                placeholder="请选择店铺"
                clearable
                @change="storeSelectChange"
              >
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              label="销售员"
              prop="seller_id"
              :label-width="formLabelWidth"
            >
              <!-- <el-input v-model="addForm.seller_id" /> -->
              <el-select
                v-model="addForm.seller_id"
                style="width: 100%"
                placeholder="请选择销售员"
                clearable
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
              <el-col>
                <el-form-item
                  label="备注"
                  prop="remark"
                  :label-width="formLabelWidth"
                >
                  <el-input v-model="addForm.remark" />
                </el-form-item>
              </el-col>
            </el-row> -->
        <el-row>
          <el-col>
            <!-- 表格 -->
            <el-table
              ref="addTableRef"
              :data="addForm.add_data"
              class="add-table"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column type="index"> </el-table-column>
              <el-table-column prop="name" label="商品名称"></el-table-column>
              <el-table-column
                prop="spec_name"
                label="商品规格"
              ></el-table-column>
              <el-table-column prop="unit" label="单位"></el-table-column>
              <el-table-column
                prop="storage_name"
                label="仓库"
              ></el-table-column>
              <el-table-column prop="unit_price" label="单价"></el-table-column>
              <el-table-column prop="quantity" label="总数量"></el-table-column>
              <!-- <el-table-column
                    prop="numbering"
                    label="商品编号"
                  ></el-table-column>
                  <el-table-column prop="specName" label="规格"></el-table-column>
                  <el-table-column prop="price" label="*采购价">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="'add_data.' + scope.$index + '.unit_price'"
                        :rules="addRules.num"
                      >
                        <el-input
                          v-model="scope.row.price"
                          size="mini"
                          min="1"
                          type="number"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column> -->

              <el-table-column label="*出库数量">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'add_data.' + scope.$index + '.num'"
                    :rules="addRules.num"
                  >
                    <el-input
                      v-model="scope.row.num"
                      size="mini"
                      min="1"
                      type="number"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="*售卖价格">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'add_data.' + scope.$index + '.sale_price'"
                    :rules="addRules.num"
                  >
                    <el-input
                      v-model="scope.row.sale_price"
                      size="mini"
                      min="0"
                      type="number"
                    ></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="200" label="*店铺分类">
                <template slot-scope="scope">
                  <el-form-item class="select">
                    <!-- <el-select
                          v-model="scope.row.class"
                          size="mini"
                          placeholder="店铺分类"
                        >
                          <el-option
                            v-for="item in storeClass"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select> -->
                    <el-cascader
                      size="mini"
                      placeholder="店铺分类"
                      :options="storeClass"
                      :props="optionProps"
                      v-model="scope.row.class"
                      filterable
                      clearable
                    >
                    </el-cascader>
                  </el-form-item>
                </template>
              </el-table-column>
              <!-- <el-table-column label="备注">
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-input v-model="scope.row.note" size="mini"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column> -->
            </el-table>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd()">取 消</el-button>
        <el-button type="primary" @click="confirmAdd()">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog
      v-dialogDrag
      title="商品详情"
      :visible.sync="detailDialogVisible"
    >
      <el-descriptions border :column="3">
        <el-descriptions-item label="商品id">{{
          detailInfo.id
        }}</el-descriptions-item>
        <el-descriptions-item label="商品名称">{{
          detailInfo.goods_name
        }}</el-descriptions-item>
        <el-descriptions-item label="产地"
          >{{ detailInfo.product_place }}
        </el-descriptions-item>
        <el-descriptions-item label="仓库"
          >{{ detailInfo.warehouse_name }}
        </el-descriptions-item>
        <el-descriptions-item label="编号">{{
          detailInfo.numbering
        }}</el-descriptions-item>
        <el-descriptions-item label="规格">{{
          detailInfo.key_name
        }}</el-descriptions-item>
        <!-- <el-descriptions-item label="入库类型">
              <el-tag type="success" v-show="detailInfo.state">显示</el-tag>
              <el-tag type="warning" v-show="!detailInfo.state"
                >隐藏</el-tag
              ></el-descriptions-item
            > -->
        <el-descriptions-item label="入库类型">{{
          detailInfo.type
        }}</el-descriptions-item>
        <el-descriptions-item label="单位">{{
          detailInfo.unit
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getDel,
  getSearch,
  getSearchDel,
  getToday,
  getStoreList,
  getShopClass,
  getUserlist,
  applyOutbound,
  getDetail,
  getOutbound,
} from '@/api/psi/storageAll.js'
import { editStorageGoods, storageGoodsInfo } from '@/api/psi/storage.js'
import { getOption2 } from '@/api/psi/commodity.js'
import Record from '@/components/Record.vue'
import lodash from 'lodash'
import { toggleRowStatus } from 'element-ui/packages/table/src/util'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      detailDialogVisible: false,
      editRules: {
        num: [{ required: true, message: '请输入库存', trigger: 'blur' }],
      },
      editStorageForm: {
        num: null,
      },
      addType: [
        { label: '采购出库' },
        { label: '生产出库' },
        { label: '调拨出库' },
        { label: '退货出库' },
        { label: '退料出库' },
        { label: '其他出库' },
      ],
      editStorageDialogVisible: false,
      loading: false,
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'son',
      },
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      delForm: {
        task_id: '',
      },
      optionsTask: [],

      dialogLog: {
        state: false,
      },
      active: 0,
      tags: [],
      recordLeft: '0',

      openState: true,
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //上级列表
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {},
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],

      checkedList: [],
      addDialogVisible: false,
      addRules: {
        type: [
          { required: true, message: '请输入出库类型', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur',
          },
        ],
        apply_time: [
          { required: true, message: '请输入出库时间', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur',
          },
        ],
        outbound_no: [
          { required: true, message: '请输入出库编号', trigger: 'blur' },
          {
            min: 1,
            max: 20,
            message: '长度在 1 到 20 个字符',
            trigger: 'blur',
          },
        ],
        seller_id: [
          { required: true, message: '请选择销售员', trigger: 'change' },
        ],
        app_id: [{ required: true, trigger: 'change', message: '请选择店铺' }],
        num: [{ required: true, message: '必填', trigger: 'blur' }],
      },
      addForm: {
        add_data: [],
      },
      storeList: [],
      storeClass: [],
      userList: [],
      detailInfo: {},
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getRowClass(row) {
      //console.log(row)
      //   if (row.row.inventory_balance === 1) {
      //     return 'row-red'
      //   }
    },
    dialogOpen() {
      this.dialogLog.state = true
    },
    addOpen() {
      this.$router.push({
        path: '/addstorage',
        query: {
          name: '增加总表',
        },
      })
    },
    setRecord(index) {
      let width = 120
      let widthAll = document.querySelector(`#recordAll`).offsetWidth
      if (widthAll == 0) {
        widthAll = 770
      }
      console.log(widthAll)
      let itemWidth = index * width + width
      if (itemWidth > widthAll) {
        let recordLeft = itemWidth - widthAll + 50
        this.recordLeft = -recordLeft + 'px'
      } else {
        this.recordLeft = '0'
      }
    },
    async close(item, index) {
      let info = await getSearchDel({ id: item.id })
      this.judge(info)
      this.logList()
    },
    changeRecord(item, index) {
      this.active = item.id
      this.setRecord(index)
      this.getInfo.info = item.name
      this.catalogue()
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page = value
      this.catalogue()
    },
    selectable(row) {
      //console.log(row)
      return row.id
    },
    //打开编辑
    editOpen(row) {
      this.editStorageDialogVisible = true
      this.editStorageForm.num = row.quantity
      this.editStorageForm.storage_id = row.id
    },
    async setEdit() {
      const { data: res } = await editStorageGoods(this.editStorageForm)
      if (res.code === 1) {
        this.editStorageDialogVisible = false

        this.$message.success('编辑商品库存成功')
        this.catalogue()
      } else {
        this.$message.error(res.message)
      }
    },
    async openDetail(row) {
      let { data: res } = await storageGoodsInfo({ storage_id: row.id })
      this.detailInfo = res.data
      this.detailDialogVisible = true
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true
      this.userdelinfo = row
    },
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
        task_id: this.delForm.task_id,
      })
      this.judge(info)
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {}
      console.log('close')
    },
    async logList() {
      let info2 = await getSearch()
      this.tags = info2.data.data
    },
    //目录接口
    async catalogue() {
      this.loading = true
      let info = await getList(this.getInfo)
      this.tableData = info.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.logList()
      let optionsTask = await this.$getOptionTask()
      this.optionsTask = optionsTask.data.data
      this.loading = false
    },
    //打开出库单
    async showAddDialog() {
      if (this.checkedList.length === 0) {
        this.$message.warning('请先勾选需要申请出库的商品！')
      } else {
        // 获取当天时间
        const res = await getToday()
        // console.log(res)
        this.addForm.apply_time = res.data.data

        const res1 = await getOutbound()
        this.addForm.outbound_no = res1.data.data

        const res2 = await getStoreList()
        this.storeList = res2.data.data

        const res3 = await getUserlist()
        this.userList = res3.data.data

        this.checkedList.forEach((item) => {
          this.addForm.add_data.push({
            name: item.name,
            spec_name: item.spec_name,
            storage_name: item.storage_name,
            unit_price: item.unit_price,
            storage_id: item.id,
            quantity: item.quantity,
            num: item.quantity,
            sale_price: item.unit_price,
            unit: item.unit,
          })
        })
        this.addDialogVisible = true
      }
    },
    //
    async storeSelectChange(val) {
      console.log(val)
      const res = await getShopClass({ app_id: val })
      this.storeClass = res.data.data
    },
    // 处理表格 勾选事件
    handleCheckedChange(val) {
      this.checkedList = val
    },
    getRowKeys(row) {
      //console.log(row.id);
      // this.rowKeyArr.push(row.id)
      return row.id
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAdd()
          done()
        })
        .catch((_) => {})
    },
    handleClose1(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.editStorageDialogVisible = false
          done()
        })
        .catch((_) => {})
    },
    cancelAdd() {
      this.addForm = {
        add_data: [],
      }

      this.addDialogVisible = false

      setTimeout(() => {
        this.$refs.addFormRef.clearValidate()
      }, 30)
    },
    // 确定添加
    async confirmAdd() {
      let form = lodash.cloneDeep(this.addForm)

      form.add_data.forEach((item) => {
        item.app_id = form.app_id
      })
      form.add_data = JSON.stringify(form.add_data)
      const res = await applyOutbound(form)
      console.log(res)
      if (res.data.code === 1) {
        this.$message.success('申请出库成功！')
        this.catalogue()
        setTimeout(() => {
          this.$refs.multipleTable.clearSelection()
        }, 30)

        this.cancelAdd()
      } else {
        this.$message.error(res.data.message)
      }
    },
    async handleShopChange() {},
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}

.add-table >>> .el-form-item {
  margin-bottom: 0 !important;
}

.add-table >>> .el-form-item .el-input {
  width: 70px !important;
}
.storage-table >>> .el-checkbox {
  zoom: 200% !important;
}
.storage-table >>> .row-red {
  background-color: orange !important;
  font-weight: 700;
}
.storage-table >>> .row-red:hover {
  background-color: orange !important;
  font-weight: 700;
}
.storage-table .warning-yellow {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #f2f200;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.storage-table .warning-red {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #870719;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.storage-table .warning-orange {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: orange;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.storage-table .warning-green {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #67c23a;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.add-table .select .el-cascader >>> .el-input {
  width: 180px !important;
}
</style>
